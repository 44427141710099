<template>
  <contracts-manager management-mode ref="contractsManager"></contracts-manager>
</template>

<script>
import ContractsManager from "../../Contracts/components/ContractsManager.vue";
export default {
  components: { ContractsManager },
  data() {
    return {};
  },
  computed: {
    sortableColumns() {
      return this.headers.filter((elm) => elm.sortable);
    },
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {},
};
</script>
